const colors = {
  lightBlue: "#0fa3e7",
  darkBlue: "#086bbd",
  lightPurple: "#d4d3dc",
  darkPurple: "#191b29",
  green: "#79a38c",
  white: "#fff",
}

export default colors
