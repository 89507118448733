import React, { useState } from "react"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"

import { FaCaretRight, FaCaretDown } from "react-icons/fa"

const MobileDropdown = ({ text, links }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div className="mobile-dropdown">
      <p>
        <span>{text} </span>
        <button onClick={() => setOpen(!isOpen)}>
          {isOpen ? <FaCaretDown /> : <FaCaretRight />}
        </button>
      </p>
      {isOpen && (
        <div>
          {links.map((link, i) => (
            <Link to={link.to} key={i}>
              {link.text}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default function BurgerMenu({ topLinks }) {
  return (
    <div className="mobile-nav">
      <Menu left>
        {topLinks.map(({ dropdown, links, text, url }, i) => (
          <div key={i}>
            {dropdown && <MobileDropdown text={text} links={links} />}
            {!dropdown && (
              <Link to={url} key={i}>
                {text}
              </Link>
            )}
          </div>
        ))}
        <div>
          <a href="tel:941-378-1762">
            941-378-1762
          </a>
        </div>
      </Menu>
    </div>
  )
}

BurgerMenu.defaultProps = {
  topLinks: [],
}
