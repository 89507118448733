/**
 * PageLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Footer from "../footer/"
import Header from "../header"
import "./layout.css"
import "./layout.scss"

const PageLayout = ({
  children,
  heroTitle,
  heroTitlePosition,
  heroBackgroundImage,
  background,
  showBlog,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <Header siteTitle={data.site.siteMetadata.title} />
      {heroBackgroundImage && (
        <div
          className="page-hero"
          style={{
            background: `url(${heroBackgroundImage}) no-repeat center center`,
            backgroundSize: "cover",
          }}
        >
          <div className={`hero-content ${heroTitlePosition}`}>
            {heroTitle && <h1>{heroTitle}</h1>}
          </div>
        </div>
      )}
      <div style={{ backgroundColor: background }}>
        <main>{children}</main>
      </div>
      <Footer showBlog={showBlog} />
    </div>
  )
}

PageLayout.defaultProps = {
  background: "#fff",
  heroTitle: "",
  heroTitlePosition: "center",
  heroBackgroundImage: "",
  showBlog: true,
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout
