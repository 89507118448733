import { Link } from "gatsby"

const links = [
  {
    text: "Home",
    url: `/`,
  },
  {
    text: "Products",
    dropdown: true,
    links: [
      { text: "Custom Glass", to: "/custom-glass", as: Link },
      { text: "Laminated Glass", to: "/laminated-glass", as: Link },
      { text: "Backpainted Glass", to: "/back-painted-glass", as: Link },
      { text: "Antique Mirror", to: "/antique-mirror", as: Link },
      { text: "Pattern Glass", to: "/pattern-glass", as: Link },
      { text: "Crackle Glass", to: "/crackle-glass", as: Link },
    ],
  },
  {
    text: "Projects",
    url: `/projects`,
  },
  {
    text: "Applications",
    dropdown: true,
    links: [
      { text: "Glass for Elevators", to: "/glass-for-elevators", as: Link },
      { text: "Glass Backsplash", to: "/glass-backsplash-applications", as: Link },
    ],
  },
  {
    text: "Company",
    dropdown: true,
    links: [
      { text: "About", to: "/about", as: Link },
      { text: "FAQ", to: "/frequenty-asked-questions", as: Link },
      { text: "Quick Links", to: "/quick-links", as: Link },
      { text: "Map", to: "https://goo.gl/maps/S3MRMYobBozweY5EA", as: Link, target: "_blank" },
      
    ],
  },
  {
    text: "Contact Us",
    url: `/contact-us`,
  },
]

export default links
