import { Link, useStaticQuery, graphql } from "gatsby"
import { Dropdown } from "semantic-ui-react"
import PropTypes from "prop-types"
import React from "react"
import "./index.scss"
import BurgerMenu from "./BurgerMenu"
import { IoLogoFacebook, IoLogoLinkedin, IoLogoYoutube } from "react-icons/io"

import links from "./links"

import colors from "../../colors"

const iconProps = {
  size: "60px",
  color: colors.darkBlue,
}

const buttonIconProps = {
  size: "90px",
}

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <BurgerMenu topLinks={[...links]} />
      <header className="site-header">
        <div className="wrapper">
          <div className="left">
            <Link to="/">
              <img src={`/images/logo.png`} className="logo" />
            </Link>
          </div>
          <div className="right">
            <div className="links">
              {links.map((l, i) =>
                l.dropdown ? (
                  <Dropdown
                    text={l.text}
                    options={l.links}
                    simple
                    item
                    to={l.url}
                    as={Link}
                    style={{ marginLeft: "1em" }}
                  />
                ) : (
                  <Link key={i} to={l.url} className="main-link">
                    {l.text}
                  </Link>
                )
              )}
            </div>
            <a href="tel:941-378-1762" className="tel-btn">
              941-378-1762
            </a>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
