import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa"
import Img from "gatsby-image"

import "./index.scss"

const Footer = ({ showBlog }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <footer>
      <div className="wrapper">
        {/* <div className="left">
          <ContactForm />
        </div> */}
        <div className="footer-logo">
          <Img fluid={data.placeholderImage.childImageSharp.fluid} />
          <div className="footer-social">
            {socialLinks.map(({ key, url, icon }) => (
              <a key={key} href={url} target="_blank">
                {icon}
              </a>
            ))}
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-link-section">
            <h3>Company</h3>
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/frequenty-asked-questions">FAQ</a>
            <a href="/quick-links">Quick Links</a>
            <a href="https://goo.gl/maps/rupHKUKMaD3K32Xm6" target="_blank">
              Map
            </a>
            <br />
            <br />
            Headquarters:
            <br />
            6441 19th St E Bldg F,
            <br />
            Sarasota, FL 34243
            <br />
            <a href="tel:941-378-1762">941-378-1762</a>
          </div>
          <div className="footer-link-section">
            <h3>Products</h3>
            <a href="/antique-mirror">Antique Mirror</a>
            <a href="/back-painted-glass">Back Painted Glass</a>
            <a href="/crackle-glass">Crackle Glass</a>
            <a href="/custom-glass">Custom Glass</a>
            <a href="/laminated-glass">Laminated Glass</a>
            <a href="/pattern-glass">Pattern Glass</a>
          </div>
          <div className="footer-link-section">
            <h3>Projects</h3>
            <a href="/textured-pattern-glass-ritz-carlton-miami-fl">
              Ritz Carlton key Biscayne, Miami, Fl
            </a>
            <a href="/back-painted-glass-atlanta-ga-top-draft-sports-bar">
              Top Draft Sports Bar, Atlanta, Ga
            </a>
            <a href="/laminated-glass-orlando-fl-westgate-resorts">
              Westgate Resorts, Orlando, Fl
            </a>
            <a href="/pattern-glass-pelicans-nest-golf-club-bonita-springs-fl">
              Pelican’s Nest, Bonita Springs, Fl
            </a>
          </div>
          <div className="footer-link-section">
            <h3>Applications</h3>
            <a href="/glass-for-elevators">Glass for Elevators</a>
            <a href="/glass-backsplash-applications">Glass Backsplash</a>
          </div>
          {/* <div className="footer-link-section">
            <h3>Resources</h3>
          </div> */}
        </div>
        {/* <p className="copyright-links">
            © {new Date().getFullYear()} ArtVue Glass. All Rights Reserved.
            <br />
            <a href="https://goo.gl/maps/nJeQB4xiWjDvyYtb6" target="_blank">
              6441 19th St E Bldg F, Sarasota, FL 34243
            </a>
            <br />
            <a href="tel:9413781762">(941)378-1762</a>
            <br />
            <a href="/privacy-policy/">Privacy Policy</a>
          </p> */}
      </div>
    </footer>
  )
}

export default Footer

const socialLinks = [
  {
    url: `https://www.facebook.com/artvueglass`,
    icon: <FaFacebook />,
  },
  {
    url: `https://www.instagram.com/artvueglass/`,
    icon: <FaInstagram />,
  },
  {
    url: `https://www.linkedin.com/company/artvue-glass-llc/`,
    icon: <FaLinkedin />,
  },
].map((m, i) => ({ ...m, key: i }))
